body
	background: #efefef
	margin: 0

	@media (width >= 992px)
		padding: 20px 0

.slider
	filter: drop-shadow(1px 1px 15px rgba(0, 0, 0, .08))
	left: 50%
	margin: auto
	position: fixed
	top: 50%
	transform: translate(-50%, -50%)

	@media (width > 768px)
		left: calc(50% - 30px)

	[data-animation-in]
		opacity: 0

	&-item
		position: relative

	&-item,
	&-title,
	&-content
		background-position: center
		background-size: cover

		&:focus
			outline: 0

	&-title,
	&-content
		position: absolute

.slick

	&-slide
		opacity: 0
		transition: opacity 0.5s ease-out

	&-active
		opacity: 1 !important
		transition: opacity 0.5s ease-in

	&-cloned
		opacity: 0.5

	&-arrow
		align-items: center
		background: #ffffff
		border: none
		display: none !important
		font-size: 0
		height: calc(50% - 5px)
		justify-content: center
		position: absolute
		right: -60px
		transition: all .5s
		width: 50px
		z-index: 10000

		@media (width > 768px)
			display: flex !important

		&:focus
			outline: 0

		&:hover
			background: #eeeeee

		&:active
			background: #cecece

		&:before
			border-left: 2px solid #888888
			border-top: 2px solid #888888
			content: ''
			display: block
			height: 15px
			width: 15px

	&-prev
		top: 0

		&:before
			transform: rotate(45deg)

	&-next
		bottom: 0

		&:before
			transform: rotate(-135deg)

	&-disabled
		opacity: 0.3
